import React from "react";
import Button from "../button/button";
import { Slide } from 'react-reveal';

function Article(props) {
    return (
        <div className="container mx-auto px-4 xl:px-24 my-32">
            <div className="flex flex-col lg:flex-row items-start justify-start">
                <Slide left>
                    <div className="w-full lg:w-7/12 flex flex-col items-center lg:items-start pr-0 lg:pr-10">
                        <div className="w-full lg:w-10/12">
                            <div className="text-center lg:text-left mb-3">
                                <span className="font-semibold text-blue-excendo py-1 px-4 rounded-full shadow-md bg-gray-50 ">{props.heading}</span>
                            </div>
                            <h2 className="text-center lg:text-left text-3xl lg:text-4xl font-semibold text-black-excendo mt-10 filter drop-shadow-md">{props.title}</h2>
                            <div className="text-center text-gray-excendo xl:text-lg mt-6 lg:mt-8 xl:mt-10 lg:text-left sm:w-3/4 mx-auto lg:w-full">{props.children}</div>
                            <div className="mt-14 flex justify-center lg:justify-start">
                                <Button
                                    color="blue"
                                    href="/kontakt"
                                    text="Jag vill veta mer"
                                />
                            </div>
                        </div>
                    </div>
                </Slide>
                <Slide right>
                    <div className="w-full lg:w-5/12 flex justify-start items-start">
                        <div className="mt-10 lg:mt-0 w-full md:w-10/12 lg:w-full mx-auto flex justify-center items-center">
                            <img className="w-full h-full"
                                src={props.image}
                                alt={props.image_alt}
                            />
                        </div>
                    </div>
                </Slide>
            </div>
        </div>
    );
}
export default Article;